<template>
  <div class="scene">
     <Head :name="'双开场景面板'" :add="'/pc/ProScene'" :adds="'/pc/ProSceneParam'" :color="true" />
    <div class="banner">
      <div class="pane">
        <div class="paneTit">双开场景面板</div>
        <div class="paneText">用途广泛，可定制</div>
        <div class="paneText">专用协议，传输稳定</div>
        <div class="paneText">美观大方，多种颜色可选</div>          
        <div class="paneText">专用频率和协议，抗干扰能力强</div>
      </div>
    </div>
    <div class="tips">
      <div class="tipsBox">
        <div class="tip">
          <div class="tipTit">易使用</div>
          <div class="tipText">四个按键，四种场景</div>
        </div>
        <div class="tip">
          <div class="tipTit">按键灯</div>
          <div class="tipText">在夜晚为你指明方向</div>
        </div>
        <div class="tip">
          <div class="tipTit">可定制</div>
          <div class="tipText">定制文字，定制场景</div>
        </div>
      </div>
    </div>
    <div class="color">
      <div class="colorTit">多种颜色，多种风格</div>
      <div class="colorText">适应你的装修风格，个性化定制用户体验</div>
      <div class="txt1">黑色</div>
      <div class="txt2">绅士灰</div>
      <div class="txt3">香槟金</div>
    </div>
    <div class="sec1">
      <div class="waveBox">
        <div class="wave1"></div>
        <div class="wave2"></div>
        <div class="wave3"></div>
        <div class="wave4"></div>
      </div>
    </div>
    
  </div>
</template>
<script>
import Head from "../../../components/Head.vue";
export default {
  components: {
    Head
  },
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 8rem;
  background: url("../../../assets/product/sceneBanner.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .pane {
    width: 7.8rem;
    height: 3.2rem;
    background: rgba(255,144,0,0.5);
    border-radius: 0px 0px 0.6rem 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3.61rem;
    text-align: left;
    font-size: 0.48rem;
    line-height: 0.48rem;
    font-weight: bold;
    color: #fff;
    .paneText {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      margin-top: 0.16rem;
      &:first-of-type {
        margin-top: 0.25rem;
      }
    }
  }
}
.tips {
  width: 100%;
  height: 2.58rem;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-content: center;
  .tipsBox {
    width: 12rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tip {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tipTit {
        font-size: 0.36rem;
        line-height: 0.36rem;
        font-weight: 800;
        color: #ff9000;
      }
      .tipText {
        margin-top: 0.4rem;
        font-size: 0.24rem;
        line-height: 0.24rem;
        font-weight: 400;
        color: #333;
      }
    }
  }
}
.color {
  width: 100%;
  height: 9rem;
  background: url("../../../assets/product/scene1.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  font-size: 0.24rem;
  line-height: 0.24rem;
  font-weight: 400;
  color: #333;
  position: relative;
  .colorTit {
    margin-top: 1.2rem;
    font-size: 0.36rem;
    line-height: 0.36rem;
    font-weight: 800;
    color: #333;
  }
  .colorText {
    margin-top: 0.38rem;
  }
  .txt1 {
    position: absolute;
    top: 683px;
    left: 483px;
    top: 6.83rem;
    left: 4.85rem;
  }
  .txt2 {
    position: absolute;
    top: 683px;
    left: 914px;
    top: 6.83rem;
    left: 9.22rem;
  }
  .txt3 {
    position: absolute;
    top: 683px;
    left: 1360px;
     top: 6.83rem;
    left: 13.7rem;
  }
}
.sec1 {
  width: 100%;
  height: 9rem;
  background: url("../../../assets/product/scene2.png");
  background-size: 100% 100%;
  position: relative;
  .waveBox {
    top: 4.1rem;
    left: 2.1rem;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: rgba(255, 237, 170, 0.07);
    animation: w 1.5s ease 0.2s infinite;
    position: absolute;
    .wave1 {
      position: absolute;
      margin-left: 0.2rem;
      margin-top: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 0.1);
      z-index: 2;
    }
    .wave2 {
      position: absolute;
      margin-left: 0.45rem;
      margin-top: 0.45rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 0.2);
      z-index: 3;
    }
    .wave3 {
      position: absolute;
      margin-left: 0.6rem;
      margin-top: 0.6rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 0.4);
      z-index: 4;
    }
    .wave4 {
      position: absolute;
      margin-left: 0.75rem;
      margin-top: 0.75rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 50%;
      background-color: rgba(255, 237, 170, 1);
      z-index: 5;
    }
    @keyframes w {
      0% {
        -moz-transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        opacity: 0;
      }
      100% {
        -moz-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        opacity: 1;
      }
    }
    @-webkit-keyframes w {
      0% {
        -moz-transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        opacity: 0;
      }
      100% {
        -moz-transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        opacity: 1;
      }
    }
  }
}


</style>
